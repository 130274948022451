import Image from 'next/image';
import React from 'react';

const TopicModal = ({ course }) => {
   return (
      <>
         <div
            className="rbt-default-modal modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <div className="modal-header">
                     <button
                        type="button"
                        className="rbt-round-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                        <i className="feather-x"></i>
                     </button>
                  </div>
                  <div className="modal-body">
                     <div className="inner rbt-default-form">
                        <div className="row">
                           <div className="col-lg-12">
                              <h5
                                 className="modal-title mb--20"
                                 id="exampleModalLabel">
                                 {course.courseTitle}
                              </h5>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="top-circle-shape"></div>
                  <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
                     <div className="rbt-card-img mt-5">
                        <div>
                           <Image
                              src={course.courseImg}
                              width={355}
                              height={244}
                              alt="Card image"
                              className="rounded-3"
                           />
                        </div>
                     </div>
                     <div className="mt-5">
                        <p className="text-center rbt-card-text">
                           {course.desc}
                        </p>
                     </div>
                     <div className="rbt-card-bottom d-flex flex-column align-items-center">
                        <div className="rbt-price mt-4">
                           <span className="text-center current-price">
                              LKR {course.price}
                           </span>
                        </div>
                        <div
                           className="border rbt-btn border-primary mt-5"
                           data-href="#courses"
                           onClick={() => window.open(course.btnLink, '_blank')}
                           style={{ cursor: 'pointer' }}>
                           <span>
                              <span className="btn-text">Join Class</span>
                              <span className="btn-icon">
                                 <i className="feather-arrow-right"></i>
                              </span>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default TopicModal;
