import PageHead from '@/pages/Head';
import BackToTop from './backToTop';
import MainPage from '../components/01-home';

export default function Home() {
   return (
      <>
         <main className="">
            <PageHead title="Home" />
            <MainPage />
            <BackToTop />
         </main>
      </>
   );
}
