import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import TestimonialModal from './TestimonialModal';
import { useState } from 'react';

import TestimonialData from '../../data/elements/testimonial.json';

const Testimonial = () => {
   const [testimonial, setTestimonial] = useState({});

   // Truncate text
   const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + '...' : text;
   };

   const truncateStyle = {
      display: '-webkit-box',
      WebkitLineClamp: 6,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
   };

   return (
      <>
         <div className="container">
            <div className="row">
               <div className="col-lg-12 mb--60">
                  <div className="section-title text-center"></div>
               </div>
            </div>

            {TestimonialData.testimonial.map((parent, parentIndex) => (
               <Swiper
                  className="testimonial-item-3-activation swiper rbt-arrow-between gutter-swiper-30"
                  slidesPerView={1}
                  key={parentIndex}
                  modules={[Navigation]}
                  navigation={{
                     nextEl: '.rbt-arrow-left',
                     prevEl: '.rbt-arrow-right'
                  }}
                  breakpoints={{
                     575: {
                        slidesPerView: 1
                     },

                     768: {
                        slidesPerView: 2
                     },

                     992: {
                        slidesPerView: 3
                     }
                  }}>
                  {parent.left.map((data, index) => (
                     <SwiperSlide className="swiper-wrapper" key={index}>
                        <div className="swiper-slide">
                           <div className="single-slide">
                              <div className="rbt-testimonial-box">
                                 <div
                                    className="relative d-flex justify-content-between flex-column variation-01 rbt-hover"
                                    onClick={() => {
                                       setTestimonial(data);
                                    }}>
                                    <div
                                       type="button"
                                       data-bs-toggle="modal"
                                       data-bs-target="#testimonialModal">
                                       <div className="inner bg-no-shadow bg-color-primary-opacity">
                                          <div className="">
                                             <div className="w-auto text-center">
                                                <div className="h-auto">
                                                   <h5 className="title">
                                                      {data.title}
                                                   </h5>
                                                   <span>
                                                      {data.position}{' '}
                                                      {/* <i>{data.company}</i> */}
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="description">
                                             <p
                                                className="subtitle-3"
                                                style={truncateStyle}>
                                                {data.desc}
                                             </p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </SwiperSlide>
                  ))}
                  <div className="rbt-swiper-arrow rbt-arrow-right">
                     <div className="custom-overfolow">
                        <i className="rbt-icon feather-arrow-right"></i>
                        <i className="rbt-icon-top feather-arrow-right"></i>
                     </div>
                  </div>
                  <div className="rbt-swiper-arrow rbt-arrow-left">
                     <div className="custom-overfolow">
                        <i className="rbt-icon feather-arrow-left"></i>
                        <i className="rbt-icon-top feather-arrow-left"></i>
                     </div>
                  </div>
               </Swiper>
            ))}
         </div>
         <TestimonialModal testimonial={testimonial} />
      </>
   );
};

export default Testimonial;
