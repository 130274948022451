import { useEffect } from 'react';
import sal from 'sal.js';
import Card from '@/components/Cards/Card';
import CallToAction from '@/components/Call-To-Action/CallToAction';
import dynamic from 'next/dynamic';
import Testimonial from '../Testimonials/Testimonial';
const MainDemoBanner = dynamic(
   () => import('@/components/01-Main-Demo/MainDemoBanner'),
   { ssr: false }
);

const MainPage = () => {
   useEffect(() => {
      sal({
         threshold: 0.01,
         once: true
      });
   }, []);

   return (
      <>
         <main className="rbt-main-wrapper">
            <div className="rbt-banner-1">
               <MainDemoBanner />
            </div>

            <div id="courses" className="rbt-course-area rbt-section-gap">
               <div className="container">
                  <div className="row mb--60">
                     <div className="col-lg-12">
                        <div className="text-center section-title">
                           <span className="subtitle bg-primary-opacity">
                              Popular Courses
                           </span>
                           <h2 className="title">
                              Enroll now in the most anticipated courses at WOW
                              English Academy!
                           </h2>
                        </div>
                     </div>
                  </div>
                  <div className="row row--15">
                     <Card
                        col="col-lg-3 col-md-6 col-sm-6 col-12"
                        mt="mt--30"
                        start={0}
                        end={4}
                        isDesc={true}
                        isUser={true}
                     />
                  </div>
               </div>
            </div>

            <div className="rbt-callto-action-area mt_dec--half mb-5">
               <CallToAction />
            </div>
            {/* <div className="rbt-testimonial-area rbt-section-gap">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="text-center section-title">
                           <span className="subtitle bg-primary-opacity">
                              Testimonials
                           </span>
                           <h2 className="title">Student feedback</h2>
                           <Testimonial />
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
         </main>
      </>
   );
};

export default MainPage;
