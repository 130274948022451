import Image from 'next/image';
import React from 'react';

const TestimonialModal = ({ testimonial }) => {
   return (
      <>
         <div
            className="rbt-default-modal modal fade"
            id="testimonialModal"
            tabIndex="-1"
            aria-labelledby="testimonialModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <div className="modal-header">
                     <button
                        type="button"
                        className="rbt-round-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                        <i className="feather-x"></i>
                     </button>
                  </div>
                  <div className="modal-body">
                     <div className="inner rbt-default-form">
                        <div className="row">
                           <div className="col-lg-12">
                              <h5
                                 className="modal-title mb--20"
                                 id="testimonialModalLabel">
                                 {testimonial.desc}
                              </h5>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="top-circle-shape"></div>
                  <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
                     <div className="mt-5">
                        <p className="text-center rbt-card-text"></p>
                     </div>
                     <div className="rbt-card-bottom d-flex flex-column align-items-center">
                        <div className="rbt-price mt-4">
                           <span className="text-center current-price">
                              {testimonial.title}{' '}
                              <i>
                                 {testimonial.position === '-'
                                    ? ''
                                    : testimonial.position}
                              </i>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default TestimonialModal;
