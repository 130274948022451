import Image from 'next/image';
import React, { useState } from 'react';

import CourseDetails from '../../data/course-details/courseData.json';
import TopicModal from './../../components/create-course/TopicModal';

const Card = ({ start, end, col, mt, isDesc, isUser }) => {
   // Set course data
   const [courseData, setCourseData] = useState({});

   return (
      <>
         {CourseDetails &&
            CourseDetails.courseDetails.slice(start, end).map((data, index) => (
               <div
                  className={`${col} ${mt}`}
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                  key={index}>
                  <div
                     onClick={() => {
                        setCourseData(data);
                     }}
                     style={{
                        height: '100%'
                     }}
                     className="relative d-flex justify-content-between flex-column rbt-card variation-01 rbt-hover">
                     <div
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal">
                        <div className="rbt-card-img">
                           <div>
                              <Image
                                 src={data.courseImg}
                                 width={355}
                                 height={244}
                                 alt="Card image"
                                 className="rounded-3"
                              />
                           </div>
                        </div>
                        <div className="rbt-card-body">
                           <h4 className="rbt-card-title d-flex justify-content-center align-items-center">
                              {data.courseTitle}
                           </h4>

                           <p className="text-center rbt-card-text">
                              {data.desc}
                           </p>
                        </div>
                     </div>
                     <div className="justify-content-center d-flex flex-column align-items-center">
                        <div className="rbt-card-bottom d-flex justify-content-center align-items-center">
                           <div className="rbt-price">
                              <span className="text-center current-price">
                                 LKR {data.price}
                              </span>
                           </div>
                        </div>
                        <div
                           className="border rbt-btn border-primary mt-5"
                           data-href="#courses"
                           onClick={() => window.open(data.btnLink, '_blank')}
                           style={{ cursor: 'pointer' }}>
                           <span>
                              <span className="btn-text">Join Class</span>
                              <span className="btn-icon">
                                 <i className="feather-arrow-right"></i>
                              </span>
                           </span>
                        </div>
                     </div>
                  </div>
                  <TopicModal course={courseData} />
               </div>
            ))}
      </>
   );
};

export default Card;
