import React, { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import 'venobox/dist/venobox.min.css';

import CallToActionData from '../../data/elements/calltoaction.json';

const CallToAction = () => {
   useEffect(() => {
      import('venobox/dist/venobox.min.js').then(venobox => {
         new venobox.default({
            selector: '.popup-video'
         });
      });
   }, []);
   return (
      <div className="container">
         {CallToActionData &&
            CallToActionData.collectionOne.map((data, index) => (
               <div className="row g-5" key={index}>
                  {data.left.map((item, innerIndex) => (
                     <div className="" key={innerIndex}>
                        <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                           <div className="row align-items-center justify-content-between w-100">
                              <div className="col-lg-12 col-xl-5">
                                 <div className="inner">
                                    <h4 className="title mb--15">
                                       <div className="text-primary h2">
                                          {item.title1}
                                       </div>
                                       {item.title2}
                                       <br />
                                       {item.title3}
                                    </h4>
                                 </div>
                              </div>
                              <div className="col-lg-12 col-xl-7 align-self-end">
                                 <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20">
                                    <Image
                                       className="w-100 rbt-radius"
                                       src={item.img}
                                       width={319}
                                       height={229}
                                       alt="Video Images"
                                    />
                                    <Link
                                       className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation"
                                       data-vbtype="video"
                                       href="https://youtu.be/inpueWOXnzE?si=pr4_3hYkb8UKwkT7"
                                       controls>
                                       <span className="play-icon"></span>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            ))}
      </div>
   );
};

export default CallToAction;
